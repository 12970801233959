import * as React from 'react'
import { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Title, useGetOne } from 'react-admin'
import ConfirmModal from '../CustomElements/ConfirmModal'

const Contact = () => {
    // https://mailtrap.io/blog/react-contact-form/
    const [state, setState] = useState({
        name: '',
        email: '',
        message: '',
        subject: 0,
    })
    const [modalInfo, setModalInfo] = useState({ title: 'Błąd', message: '' })
    const [show, setShow] = useState(false)

    const showModal = () => {
        setShow(true)
    }

    const hideModal = () => {
        setShow(false)
    }

    const { data: userData } = useGetOne('pk_users', { id: `pk_users/me` })

    useEffect(() => {
        if (state.name === '' && userData !== undefined) {
            setState(
                {
                    name: userData.firstName,
                    email: userData.email,
                    message: state.message,
                    subject: parseInt(state.subject),
                },
                [state.name, state.message, state.subject, userData]
            )
        }
    })

    const resetForm = () => {
        setState({ name: '', email: '', message: '', subject: 0 })
        document.getElementById('name').value = ''
        document.getElementById('email').value = ''
        document.getElementById('subject').value = 0
        document.getElementById('message').value = ''
    }

    const onNameChange = (event) => {
        setState({
            name: event.target.value,
            email: state.email,
            message: state.message,
            subject: state.subject,
        })
    }

    const onEmailChange = (event) => {
        setState({
            email: event.target.value,
            name: state.name,
            message: state.message,
            subject: state.subject,
        })
    }

    const onMessageChange = (event) => {
        setState({
            message: event.target.value,
            email: state.email,
            name: state.name,
            subject: state.subject,
        })
    }

    const onSubjectChange = (event) => {
        setState({
            subject: parseInt(event.target.value),
            email: state.email,
            name: state.name,
            message: state.message,
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        let sendContactFormButton = document.getElementById('sendContactForm')
        sendContactFormButton.style.display = 'none'

        const token = localStorage.getItem('token')
        let headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(
            `${process.env.REACT_APP_API_ENTRYPOINT}/contact_form`,
            {
                method: 'POST',
                body: JSON.stringify(state),
                headers: headers,
            }
        )
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                setModalInfo({
                    title: 'Błąd wysyłania',
                    message: 'Odśwież stronę i spróbuj ponownie',
                })
                showModal()
            } else {
                setModalInfo({
                    title: 'Wiadomość została wysłana',
                    message:
                        'Dziękujemy za wiadomość. Postaramy się odpowiedzieć najszybciej jak to możliwe',
                })
                showModal()
                resetForm()
            }

            sendContactFormButton.style.display = 'block'
        })
    }

    return (
        <Card>
            <Title title="Formularz kontaktowy" />
            <ConfirmModal //mine - means good :)
                isOpen={show}
                title={modalInfo.title}
                content={modalInfo.message}
                //confirm="Ok"
                cancel="Ok"
                onConfirm={() => {
                    hideModal()
                }}
                onClose={() => {
                    hideModal()
                }}
            />
            <CardContent>
                <section className="contact-form container">
                    <div className="row mx-auto">
                        <div className="col-12 row mx-auto position-relative pr-5">
                            <div className="contact-form-left text-white rounded bg-orange p-3 pt-3 position-absolute">
                                <h1 className="font-weight-light display-3">
                                    Kontakt z nami
                                </h1>
                                <h3 className="mb-5">
                                    Nasi doradcy są do Twojej dyspozycji
                                </h3>
                                <div className="contact-form-in">
                                    <form
                                        id="contact-form"
                                        onSubmit={handleSubmit.bind(this)}
                                        method="POST"
                                    >
                                        <section className="form-fields">
                                            <div className="row mb-1">
                                                <div className="col">
                                                    <input
                                                        className="form-control bg-transparent"
                                                        name="name"
                                                        id="name"
                                                        required
                                                        placeholder="Twoje imię i nazwisko"
                                                        type="text"
                                                        value={state.name}
                                                        onChange={onNameChange.bind(
                                                            this
                                                        )}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input
                                                        className="form-control bg-transparent"
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        placeholder="twój@email.pl"
                                                        required
                                                        aria-describedby="emailHelp"
                                                        value={state.email}
                                                        onChange={onEmailChange.bind(
                                                            this
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row my-4">
                                                <div className="col">
                                                    <select
                                                        name="subject"
                                                        id="subject"
                                                        onChange={onSubjectChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        className="form-control form-control-select rounded border-0"
                                                    >
                                                        <option
                                                            value=""
                                                            selected=""
                                                            disabled=""
                                                        >
                                                            Wybierz temat
                                                            zgłoszenia
                                                        </option>
                                                        <option value="4">
                                                            spytaj o realizację
                                                            / status złożonego
                                                            zamówienia lub
                                                            poproś o fakturę
                                                        </option>
                                                        <option value="6">
                                                            umowy z NFZ,
                                                            realizacja i
                                                            rozliczenie zleceń
                                                            na zaopatrzenie w
                                                            wyroby medyczne
                                                        </option>
                                                        <option value="5">
                                                            w sprawach
                                                            produktowych,
                                                            pytania, sugestie
                                                            oraz szkolenia
                                                        </option>
                                                        <option value="3">
                                                            wezwania do zapłaty,
                                                            uzgodnienia salda
                                                            lub
                                                            przeterminowanych
                                                            płatności
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row m-1'>
                                            <textarea
                                                        required
                                                        className="form-control bg-transparent"
                                                        id="message"
                                                        name="message"
                                                        rows="6"
                                                        value={state.message}
                                                        onChange={onMessageChange.bind(
                                                            this
                                                        )}
                                                        style={{minHeight:'5.5rem'}}
                                                        placeholder="Treść wiadomości"
                                            ></textarea>
                                            </div>
                                            <div className="row">
                                                <div className="mt-1">
                                                    <button
                                                        id="sendContactForm"
                                                        className="text-orange rounded bg-white pt-2 pb-2 pl-5 pr-5 bg-white text-center text-uppercase font-weight-bold w-100"
                                                        type="submit"
                                                    >
                                                        Wyślij
                                                    </button>
                                                </div>
                                            </div>
                                            
                                        </section>
                                    </form>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end ms-3">
                                <img src="img/pani1.png" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </CardContent>
        </Card>
    )
}

export default Contact
