import React, { useEffect } from 'react'
import { Navigate, Route } from 'react-router-dom'
import { Resource, CustomRoutes, defaultTheme, defaultDarkTheme, localStorageStore, CheckForApplicationUpdate } from 'react-admin'
import { fetchHydra as baseFetchHydra, HydraAdmin, hydraDataProvider as baseHydraDataProvider, useIntrospection } from '@api-platform/admin'
import { parseHydraDocumentation } from '@api-platform/api-doc-parser'

// config
import authProvider from './config/authProvider'
import PermissionHandler from './config/PermissionHandler'

// pages
import './css/style.css'
import Login from './layout/Login/Login'
import Menu from './layout/Menu/Menu'
import NotFound from './layout/NotFound'
import Dashboard from './layout/Dashboard/Dashboard'
import MainLayout from './layout/MainLayout'
// pages - end

// custom views
import ConsentsList from './layout/Consent/List'
import ConsentsCreate from './layout/Consent/Create'
import ConsentsEdit from './layout/Consent/Edit'

import DepartmentsList from './layout/Departments/DepartmentsList'
import DepartmentsCreate from './layout/Departments/DepartmentsCreate'
import DepartmentsNewOrder from './layout/Departments/DepartmentsNewOrder'
import DpsOrderTemplateList from './layout/Departments/partials/DpsOrderTemplateList'

import DepartmentUsersList from './layout/DepartmentUsers/DepartmentUsersList'
import DepartmentUsersCreate from './layout/DepartmentUsers/DepartmentUsersCreate'
import DepartmentUsersEdit from './layout/DepartmentUsers/DepartmentUsersEdit'

import PriceListsList from './layout/PriceLists/List'
import PriceListsEdit from './layout/PriceLists/Edit'

import RegulationsList from './layout/Regulations/List'
import RegulationsEdit from './layout/Regulations/Edit'
import RegulationsCreate from './layout/Regulations/Create'

import InvoicesList from './layout/Invoices/InvoicesList'

import OrdersList from './layout/Orders/OrdersList'
import OrdersShow from './layout/Orders/OrdersShow'

import PickupPointsList from './layout/PickupPoints/PickupPointsList'
import PickupPointsShow from './layout/PickupPoints/PickupPointsShow'
import PickupPointsEdit from './layout/PickupPoints/PickupPointsEdit'

import PayersList from './layout/Payers/PayersList'
import PayersEdit from './layout/Payers/PayersEdit'
import PayersShow from './layout/Payers/PayersShow'

import PayerNetworksList from './layout/PayerNetworks/List'
import PayerNetworksShow from './layout/PayerNetworks/Show'
import PayerNetworksEdit from './layout/PayerNetworks/Edit'
import PayerNetworksCreate from './layout/PayerNetworks/Create'

import PartnersList from './layout/Partners/List'

import PayerDiscountsList from './layout/PayerDiscounts/PayerDiscountsList'
import PayerDiscountsEdit from './layout/PayerDiscounts/PayerDiscountsEdit'

import PartnerUsersList from './layout/PartnerUsers/List'
import PartnerUsersCreate from './layout/PartnerUsers/Create'
import PartnerUsersEdit from './layout/PartnerUsers/Edit'

import PartnerEmailsList from './layout/PartnerEmails/List'
import PartnerEmailsEdit from './layout/PartnerEmails/Edit'
import PartnerEmailsCreate from './layout/PartnerEmails/Create'

import PayerNetworkUsersList from './layout/PayerNetworkUsers/List'
import PayerNetworkUsersCreate from './layout/PayerNetworkUsers/Create'
import PayerNetworkUsersEdit from './layout/PayerNetworkUsers/Edit'

import PayerUsersList from './layout/PayerUsers/PayerUsersList'
import PayerUsersCreate from './layout/PayerUsers/PayerUsersCreate'
import PayerUsersEdit from './layout/PayerUsers/PayerUsersEdit'

import PkUserList from './layout/PkUser/PkUserList'
import PkUserCreate from './layout/PkUser/PkUserCreate'
import PkUserEdit from './layout/PkUser/PkUserEdit'

import PatientsList from './layout/Patients/PatientsList'
import PatientsCreate from './layout/Patients/PatientsCreate'
import PatientsEdit from './layout/Patients/PatientsEdit'
import PatientsShow from './layout/Patients/PatientsShow'

import SamplesList from './layout/Samples/SamplesList'
import SamplesCreate from './layout/Samples/SamplesCreate'
import SamplesEdit from './layout/Samples/SamplesEdit'

// import TutorialsList from './layout/Tutorials/TutorialsList'
// import TutorialsCreate from './layout/Tutorials/TutorialsCreate'
// import TutorialsEdit from './layout/Tutorials/TutorialsEdit'
// import TutorialsShow from './layout/Tutorials/TutorialsShow'

import MediaObjectsList from './layout/MediaObjects/MediaObjectsList'
import MediaObjectCreate from './layout/MediaObjects/MediaObjectsCreate'

import BannerList from './layout/Banners/BannerList'
import BannerCreate from './layout/Banners/BannerCreate'
import BannerEdit from './layout/Banners/BannerEdit'

import WorkersList from './layout/Workers/WorkersList'
import WorkersEdit from './layout/Workers/WorkersEdit'
import WorkersCreate from './layout/Workers/WorkersCreate'

import IndividualOrderList from './layout/IndividualOrder/IndividualOrderList'
import IndividualOrderCreate from './layout/IndividualOrder/IndividualOrderCreate'

import RefundDocumentRealizationList from './layout/RefundDocumentRealization/RefundDocumentRealizationList'

import RefundationOtherInfo from './layout/RefundationMenu/RefundationOtherInfo'
import Trainings from './layout/Trainings/Training'

import ResetPassword from './layout/Register/ResetPassword'
import HashLogin from './layout/Register/HashLogin'
import Calculator from './layout/Calculator/Calculator'
import MyData from './layout/MyData/MyData'
import UserNotificationsList from './layout/UserNotifications/UserNotificationsList'
import Documents from './layout/Documents/Documents'
import RefundationMenu from './layout/RefundationMenu/RefundationMenu'
import Contact from './layout/Contact/Contact'
import FormLayout from './layout/Raports/FormLayout'
import PriceRefundation from './layout/PriceRefundation/PriceRefundation'
import RefundationContact from './layout/RefundationMenu/RefundationContact'
import RaportsLayout from './layout/Raports/RaportsLayout'
import RaportsDps from './layout/Raports/RaportsDps'
import PickupPointsRefund from './layout/PickupPoints/PickupPointsRefund'
import FastOrderNew from './layout/FastOrder/FastOrderNew'
import OrderTemplateList from './layout/FastOrder/OrderTemplate/OrderTemplateList'
import TechnicalPause from './layout/TechnicalPause/TechnicalPause'
import InstitutionShow from './layout/Payers/Institution/InstitutionShow'
import InstitutionEdit from './layout/Payers/Institution/InstitutionEdit'
import AdminControlPanel from './layout/AdminControlPanel/AdminControlPanel'
//import { VoivodeshipsList, VoivodeshipsEdit } from "./layout/oldVoivodeships";
// custom views - end
// i18n
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import polishMessages from 'ra-language-polish'
import { AppUpdatedNotification } from './layout/CustomElements/UpdateNotification'
import ResetPasswordForm from './layout/Register/ResetPasswordForm'
import Reclamations from './layout/Reclamations/Reclamations'
import customFetchHydra from './config/customFetchHydra'

//import * as extendMessages from "./i18n/ts";

const messages = {
    pl: {
        ...polishMessages,
        //...extendMessages.pl
    },
    en: englishMessages,
}
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'pl')
// i18n - end

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT

const getHeaders = () =>
    localStorage.getItem('token')
        ? {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              //'Locale': localStorage.getItem("locale") ? localStorage.getItem("locale") : process.env.REACT_APP_DEFAULT_LANG,
          }
        : {}
const fetchHydra = (url, options = {}) =>
    customFetchHydra(url, {
        ...options,
        headers: getHeaders,
    })
const RedirectToLogin = () => {
    const introspect = useIntrospection()

    if (localStorage.getItem('token')) {
        introspect()
        return <></>
    }
    return <Navigate to="/login" />
}

const apiDocumentationParser = (setRedirectToLogin) => async () => {
    try {
        setRedirectToLogin(false)

        return await parseHydraDocumentation(entrypoint, {
            headers: getHeaders,
        })
    } catch (result) {
        const { api, response, status } = result
        if (status !== 401 || !response) {
            throw result
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem('token')

        setRedirectToLogin(true)

        return {
            api,
            response,
            status,
        }
    }
}

const dataProvider = (setRedirectToLogin) =>
    baseHydraDataProvider({
        entrypoint: entrypoint,
        httpClient: fetchHydra,
        apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
        useEmbedded: true,
    })

const theme = {
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: '#240a51',
        },
        white: '#fff',
    },
    typography: {
        ...defaultTheme.typography,
        fontFamily: "'Lato', sans-serif",
    },
    components: {
        ...defaultTheme.components,
        RaAppBar: {
            color: '#fff',
        },
    },
}
const darkTheme = {
    ...theme,
    palette: {
        white: '#fff',
        primary: {
            main: '#fff',
        },
        mode: 'dark',
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    '.dash-card': {
                        background: '#0c0c0c',
                        boxShadow: '0 3.6px 7.9px rgba(255, 255, 255, 0.035), 0 29px 63px rgba(255, 255, 255, 0.07)',
                        borderRadius: '5px',
                        padding: '4% 2%',
                        color: '#e18a3d',
                        cursor: 'pointer',
                        textDecoration: 'none',
                    },
                    '.dash-card:hover': {
                        background: '#d4731c',
                        transition: 'all 200ms',
                    },
                },
            },
            //      `
            //         .dash-card {
            //             background: #fff;

            //             border-radius: 5px;
            //             padding: 4% 2%;
            //             color: #e18a3d;
            //             cursor: pointer;
            //             text-decoration: none;
            //         }
            //   `,
        },
    },
}

const STORE_VERSION = '1'

const INTERVAL = 60 * 1000

export default () => {
    const [redirectToLogin, setRedirectToLogin] = React.useState(false)
    const [maintaince, setMaintaince] = React.useState(false)

    useEffect(() => {
        authProvider.getMaintaince().then((result) => setMaintaince(result))
    }, [])

    if (maintaince?.for_user) {
        return <div dangerouslySetInnerHTML={{ __html: maintaince?.body }} />
    }

    return (
        <HydraAdmin
            title="Panel Klienta TZMOONLINE"
            locale="pl"
            entrypoint={entrypoint}
            dataProvider={dataProvider(setRedirectToLogin)}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            // pages
            menu={Menu}
            loginPage={Login}
            dashboard={Dashboard}
            catchAll={NotFound}
            layout={MainLayout}
            requireAuth
            theme={theme}
            darkTheme={darkTheme}
            defaultTheme={theme}
            store={localStorageStore(STORE_VERSION)}
        >
            {(permissions) => [
                <Resource name="voivodeships" list={null} />,

                (PermissionHandler(permissions, 'Consents', 'Read') || PermissionHandler(permissions, 'Consents', 'Checked')) && (
                    <Resource
                        name="consents"
                        list={ConsentsList}
                        create={PermissionHandler(permissions, 'Consents', 'Create') && ConsentsCreate}
                        edit={PermissionHandler(permissions, 'Consents', 'Update') && ConsentsEdit}
                    />
                ),

                PermissionHandler(permissions, 'Invoices', 'Read') && <Resource name="invoices" list={InvoicesList} />,

                PermissionHandler(permissions, 'Orders', 'Read') && <Resource name="orders" list={OrdersList} show={OrdersShow} />,

                <Resource name="product_orders" />,
                <Resource name="products" />,
                <Resource name="signin" />,

                PermissionHandler(permissions, 'PkUsers', 'Read') && (
                    <Resource
                        name="pk_users"
                        list={PkUserList}
                        create={PermissionHandler(permissions, 'PkUsers', 'Create') && PkUserCreate}
                        edit={PermissionHandler(permissions, 'PkUsers', 'Update') && PkUserEdit}
                    />
                ),

                PermissionHandler(permissions, 'Partners', 'Read') && <Resource name="partners" list={PartnersList} />,

                PermissionHandler(permissions, 'PayerNetworks', 'Read', 'Create') && (
                    <Resource
                        name="payer_networks"
                        list={PayerNetworksList}
                        edit={PermissionHandler(permissions, 'PayerNetworks', 'UpdatePriceList') && PayerNetworksEdit}
                        create={PermissionHandler(permissions, 'PayerNetworks', 'Create') && PayerNetworksCreate}
                        // create={
                        //   PermissionHandler(permissions, "PayerNetworks", "Create") && PayersCreate
                        // }
                        show={PermissionHandler(permissions, 'PayerNetworks', 'Update') && PayerNetworksShow}
                    />
                ),

                PermissionHandler(permissions, 'Payers', 'Read') && (
                    <Resource
                        name="payers"
                        list={PayersList}
                        edit={PermissionHandler(permissions, 'Payers', 'Update') && PayersEdit}
                        show={PermissionHandler(permissions, 'Payers', 'Update') && PayersShow}
                    >
                        <Route path=":payerId/institution" element={<InstitutionShow />} />,
                        <Route path=":payerId/institution/edit" element={<InstitutionEdit />} />,
                    </Resource>
                ),

                PermissionHandler(permissions, 'PayerDiscounts', 'Read') && (
                    <Resource
                        name="payer_discounts"
                        list={PayerDiscountsList}
                        edit={PermissionHandler(permissions, 'PayerDiscounts', 'Update') ? PayerDiscountsEdit : null}
                    />
                ),

                PermissionHandler(permissions, 'PayerUsers', 'Read') && (
                    <Resource
                        name="payer_users"
                        list={PayerUsersList}
                        //create={PayerUsersCreate}
                        create={PermissionHandler(permissions, 'PayerUsers', 'Create') ? PayerUsersCreate : null}
                        edit={PermissionHandler(permissions, 'PayerUsers', 'Update') ? PayerUsersEdit : null}
                    />
                ),

                PermissionHandler(permissions, 'PayerNetworkUsers', 'Read') && (
                    <Resource
                        name="payer_network_users"
                        list={PayerNetworkUsersList}
                        create={PermissionHandler(permissions, 'PayerNetworkUsers', 'Create') ? PayerNetworkUsersCreate : null}
                        edit={PermissionHandler(permissions, 'PayerNetworkUsers', 'Update') ? PayerNetworkUsersEdit : null}
                    />
                ),

                PermissionHandler(permissions, 'PartnerUsers', 'Read') && (
                    <Resource
                        name="partner_users"
                        list={PartnerUsersList}
                        create={PermissionHandler(permissions, 'PartnerUsers', 'Create') ? PartnerUsersCreate : null}
                        edit={PermissionHandler(permissions, 'PartnerUsers', 'Update') ? PartnerUsersEdit : null}
                    />
                ),

                PermissionHandler(permissions, 'PayerUsers', 'Read') && (
                    <Resource
                        name="payer_users"
                        list={PayerUsersList}
                        //create={PayerUsersCreate}
                        create={PermissionHandler(permissions, 'PayerUsers', 'Create') ? PayerUsersCreate : null}
                        edit={PermissionHandler(permissions, 'PayerUsers', 'Update') ? PayerUsersEdit : null}
                    />
                ),

                PermissionHandler(permissions, 'PickupPoints', 'Read') && (
                    <Resource
                        name="pickup_points"
                        list={PickupPointsList}
                        show={PickupPointsShow}
                        edit={PermissionHandler(permissions, 'PickupPoints', 'Update') ? PickupPointsEdit : null}
                    />
                ),

                PermissionHandler(permissions, 'PriceLists', 'Read') && (
                    <Resource
                        name="price_lists"
                        list={PriceListsList}
                        // show={PriceListsShow}
                        edit={PermissionHandler(permissions, 'PriceLists', 'Update') ? PriceListsEdit : null}
                    />
                ),

                (PermissionHandler(permissions, 'Regulations', 'Read') || PermissionHandler(permissions, 'Regulations', 'Checked')) && (
                    <Resource
                        name="regulations"
                        list={PermissionHandler(permissions, 'Regulations', 'Read') && RegulationsList}
                        edit={PermissionHandler(permissions, 'Regulations', 'Update') ? RegulationsEdit : null}
                        create={PermissionHandler(permissions, 'Regulations', 'Create') ? RegulationsCreate : null}
                    />
                ),

                PermissionHandler(permissions, 'Workers', 'Read') && (
                    <Resource
                        name="workers"
                        list={WorkersList}
                        edit={PermissionHandler(permissions, 'Workers', 'Update') && WorkersEdit}
                        create={PermissionHandler(permissions, 'Workers', 'Create') && WorkersCreate}
                    />
                ),

                PermissionHandler(permissions, 'Samples', 'Read') && (
                    <Resource
                        name="samples"
                        list={SamplesList}
                        create={PermissionHandler(permissions, 'Samples', 'Create') && SamplesCreate}
                        edit={PermissionHandler(permissions, 'Samples', 'Update') && SamplesEdit}
                    />
                ),

                // PermissionHandler(permissions, 'Tutorials', 'Read') && (
                //     <Resource
                //         name="tutorials"
                //         list={TutorialsList}
                //         create={
                //             PermissionHandler(
                //                 permissions,
                //                 'Tutorials',
                //                 'Create'
                //             ) && TutorialsCreate
                //         }
                //         edit={
                //             PermissionHandler(
                //                 permissions,
                //                 'Tutorials',
                //                 'Update'
                //             ) && TutorialsEdit
                //         }
                //         show={TutorialsShow}
                //     />
                // ),
                PermissionHandler(permissions, 'MediaObjects', 'Read') && (
                    <Resource
                        name="media_objects"
                        list={MediaObjectsList}
                        create={
                            PermissionHandler(
                                permissions,
                                'MediaObjects',
                                'Create'
                            ) && MediaObjectCreate
                        }
                    />
                ),

                PermissionHandler(permissions, 'Banners', 'Read') && (
                    <Resource
                        name="banners"
                        list={BannerList}
                        create={
                            PermissionHandler(
                                permissions,
                                'Banners',
                                'Create'
                            ) && BannerCreate
                        }
                        edit={
                            PermissionHandler(
                                permissions,
                                'Banners',
                                'Update'
                            ) && BannerEdit
                        }
                    />
                ),

                PermissionHandler(permissions, 'PartnerEmails', 'Read') && (
                    <Resource name="partner_emails" list={PartnerEmailsList} edit={PartnerEmailsEdit} create={PartnerEmailsCreate} />
                ),

                <Resource name="order_templates" />,
                <Resource name="refund_documents" />,

                <Resource name="tutorial_payers" />,
                <Resource name="partner_const_discounts" />,

                PermissionHandler(permissions, 'IndividualOrders', 'Read') && (
                    <Resource
                        name="individual_orders"
                        list={IndividualOrderList}
                        create={PermissionHandler(permissions, 'IndividualOrders', 'Create') && IndividualOrderCreate}
                    />
                ),
                PermissionHandler(permissions, 'Departments', 'Read') && (
                    <Resource name="departments" list={DepartmentsList} create={DepartmentsCreate} recordRepresentation="name">
                        <Route path="new_order" element={<DepartmentsNewOrder />} />,
                        <Route path="templates" element={<DpsOrderTemplateList />} />,
                    </Resource>
                ),
                PermissionHandler(permissions, 'DepartmentUsers', 'Read') && (
                    <Resource name="department_users" list={DepartmentUsersList} create={DepartmentUsersCreate} edit={DepartmentUsersEdit} />
                ),
                PermissionHandler(permissions, 'Patients', 'Read') && (
                    <Resource name="patients" list={PatientsList} create={PatientsCreate} edit={PatientsEdit} show={PatientsShow} />
                ),

                <Resource name="patient_products" />,

                PermissionHandler(permissions, 'RefundDocumentRealization', 'Read') && (
                    <Resource name="refund_document_realizations" list={RefundDocumentRealizationList} />
                )
            ]}

            <CustomRoutes>
                {redirectToLogin ? <Route path="/" element={<RedirectToLogin />} /> : null}
                <Route exact path="/calculator" element={<Calculator />} />,
                <Route exact path="/mydata" element={<MyData />} />,
                <Route exact path="/user-notifications" element={<UserNotificationsList />} />
                ,
                <Route exact path="/documents" element={<Documents />} />,
                <Route exact path="/refundation-menu" element={<RefundationMenu />} />
                ,
                <Route
                    exact
                    path="/media_object"
                    element={<MediaObjectsList />}
                />
                ,
                <Route exact path="/banner" element={<BannerList />} />
                <Route exact path="/contact-form" element={<Contact />} />,
                <Route exact path="/raports" element={<RaportsLayout />} />,
                <Route exact path="/raports_dps" element={<RaportsDps />} />,
                <Route path="/form-raports/*" element={<FormLayout />} />,
                <Route path="/refundation-price/*" element={<PriceRefundation />} />
                <Route path="/refundation-other/*" element={<RefundationOtherInfo />} />
                ,
                <Route
                    path="/refundation-contact/*"
                    element={<RefundationContact />}
                />
                ,
                <Route path="/trainings/*" element={<Trainings />} />,
                <Route path="/pickup-points-refund" element={<PickupPointsRefund />} />
                ,
                <Route path="/admin-control-panel" element={<AdminControlPanel />} />,
                <Route path="/fast-order/new" element={<FastOrderNew />} />,
                <Route path="/fast-order/templates" element={<OrderTemplateList />} />
                ,
                <Route path="/technical-pause" element={<TechnicalPause />} />,

                <Route path="/reclamations" element={<Reclamations />} />,
            </CustomRoutes>
            <CustomRoutes noLayout>
                <Route exact path="/reset_password" element={<ResetPassword />} noLayout />
                ,
                <Route exact path="/login_hash" element={<HashLogin />} noLayout />
                ,
                <Route exact path="/reset_password_form" element={<ResetPasswordForm />} noLayout />,
            </CustomRoutes>
            <CheckForApplicationUpdate
                interval={INTERVAL}
                notification={AppUpdatedNotification}
            />
        </HydraAdmin>
    )
}
