import decodeJwt from 'jwt-decode'
import md5 from 'md5'

function createHeader() {
    const token = localStorage.getItem('token')
    const headers = new Headers()

    headers.append('Authorization', 'Bearer ' + token)

    return headers
}

async function submitOrder(formData) {
    const request = new Request(
        `${process.env.REACT_APP_API_ENTRYPOINT}/dps/send-order`,
        {
            method: 'POST',
            headers: createHeader(),
            body: formData,
        }
    )
    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.json().then((e) => {
                throw e?.['hydra:description']
            })
        } else return response.json()
    })
}



export default {
    submitOrder
}
