import * as React from 'react'
import useStateRef from 'react-usestateref'
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Select,
    MenuItem,
    InputAdornment,
    TextField,
    IconButton,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Title, useGetList, useStore, useDataProvider, useRedirect } from 'react-admin'
import { useSnackbar } from 'notistack'
import { useConfirm } from 'material-ui-confirm'
import OrderLines from './OrderLines'
import dpsProvider from '../../../config/dpsProvider'
import dayjs from 'dayjs'

export default ({
    setSummaryPage,
    dpsPreferences,
    dpsDetails,
    refundRange,
    refundRangeOptions,
    getTitle,
    patients,
    getPatientIndex,
    PatientSummary,
    refundStatuses,
    summaryAll,
    forceUpdate,
    handleChange,
    Summary,
    expandAll,
    setDpsPreferences,
    setDpsDetails,
    setSummaryAll,
    setShouldSelectAll,
}) => {
    const confirm = useConfirm()
    const redirect = useRedirect()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [sending, setSending, sendingRef] = useStateRef(false)

    const submitOrder = () => {
        setSending(true)
        const orderLines = dpsDetails
            .filter(
                (oL) => oL?.selected && (dpsPreferences.orderType == 1 ? refundStatuses?.[oL?.patient_id]?.[dayjs().format('YYYY-MM')] == 'B' : true)
            )
            .map((_oL) => ({
                patientId: _oL.patient_id,
                isCash: _oL.isCash ?? false,
                products: _oL?.products?.map(({ description, categories, attributes, features, ...other }) => other),
                summary: {
                    summaryTotal: _oL.summaryTotal,
                    summaryDps: _oL.summaryDps,
                    summaryNfz: _oL.summaryNfz,
                    summaryPatient: _oL.summaryPatient,
                },
            }))
        const formData = new FormData()
        formData.append('orderPreferences', JSON.stringify({ ...dpsPreferences, refundRange }))
        formData.append('orderLines', JSON.stringify(orderLines))
        formData.append('orderSummary', JSON.stringify(summaryAll))

        dpsProvider
            .submitOrder(formData)
            .then((resp) => {
                confirm({
                    title: 'Zamówienie zostało pomyślnie złożono',
                    description: `Twoje zamówienie zostało złożone i niedługo przesłane do realizacji.`,
                    confirmationText: 'Przejdź na stronę główną',
                    confirmationButtonProps: {
                        variant: 'contained',
                        color: 'success',
                    },
                    allowClose: false,
                    hideCancelButton: true,
                }).then(() => {
                    setDpsPreferences(false)
                    setDpsDetails([])
                    setSummaryAll({})
                    setShouldSelectAll(true)
                    redirect('/')
                })
            })
            .catch((resp) => {
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            })
            .finally(() => {
                setSending(false)
            })
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    size="small"
                    variant="outlined"
                    color="warning"
                    onClick={() => {
                        setSummaryPage(false)
                    }}
                >
                    Wróć do edycji zamówienia
                </Button>
                <Typography variant="subtitle1" maxWidth={400}>
                    {dpsPreferences && getTitle(dpsPreferences)}
                </Typography>
                {dpsPreferences.orderType == 1 && (
                    <TextField
                        select
                        variant="outlined"
                        sx={{ minWidth: 200 }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Okres:</InputAdornment>,
                        }}
                        value={refundRange}
                        disabled
                    >
                        {refundRangeOptions.map((o) => (
                            <MenuItem value={o.value}>{o.label}</MenuItem>
                        ))}
                    </TextField>
                )}
            </Box>
            {patients && (
                <Box sx={{ marginTop: 3 }}>
                    {patients?.map(
                        (patient) =>
                            dpsDetails.find((p) => p.patient_id == patient._id && p?.products?.length > 0) && (
                                <Box sx={{ display: 'flex' }}>
                                    <Accordion
                                        key={patient.id}
                                        expanded={expandAll}
                                        onChange={handleChange(patient.id)}
                                        sx={{
                                            flex: 1,
                                            filter:
                                                dpsPreferences.orderType == 1 &&
                                                patient?.refundDocument &&
                                                refundStatuses?.[patient._id]?.[dayjs().format('YYYY-MM')] != 'B'
                                                    ? 'brightness(95%)'
                                                    : 'unset',
                                        }}
                                    >
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <PatientSummary
                                                patient={patient}
                                                active={expandAll}
                                                dpsDetails={dpsDetails}
                                                _refundStatuses={refundStatuses}
                                                dpsPreferences={dpsPreferences}
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box>
                                                <OrderLines patient={patient} forceUpdate={forceUpdate} refundRange={refundRange} editable={false} />
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '4px' }}>
                                        <Checkbox
                                            sx={{ alignSelf: 'center' }}
                                            disabled
                                            checked={dpsDetails[getPatientIndex(patient._id)]?.selected}
                                        />
                                        {dpsDetails[getPatientIndex(patient._id)]?.selected && (
                                            <FormControlLabel
                                                control={<Switch checked={dpsDetails[getPatientIndex(patient._id)]?.isCash} disabled />}
                                                label={
                                                    <Typography fontSize="0.75rem" variant="body2">
                                                        Czy płatność nastąpi gotówką
                                                    </Typography>
                                                }
                                                labelPlacement="top"
                                                sx={{ margin: 0 }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            )
                    )}
                </Box>
            )}
            <Box sx={{ display: 'flex', margin: '30px 0' }}>
                <Box sx={{ flex: 1 }} />
                <Box sx={{ flex: 1 }}>
                    <Divider sx={{ margin: '10px 0' }} />
                    <Summary />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
                        <Button disabled={sendingRef.current} onClick={() => submitOrder()} variant="contained" color="success">
                            Zrealizuj zamówienie
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
