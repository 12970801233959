import MuiButton from '@mui/material/Button'
import decodeJwt from 'jwt-decode'
import md5 from 'md5'
import * as React from 'react'
import {
    BooleanField,
    Button,
    DateField,
    FunctionField,
    NumberField,
    ReferenceField,
    ReferenceManyField,
    ReferenceArrayField,
    Show,
    SimpleShowLayout,
    TextField,
    useListContext,
    usePermissions,
    useRecordContext,
    SingleFieldList,
    ChipField,
} from 'react-admin'
import PermissionHandler from '../../config/PermissionHandler'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import { CustomTitle } from '../CustomElements/CustomTitle'
import { CustomPagination } from '../CustomElements/CustomPagination'

export default (props, source) => {
    const { isLoading, permissions } = usePermissions()

    const CustomAddToCartButton = (props) => {
        const record = useRecordContext()
        let token = localStorage.getItem('token')
        let decodedToken = decodeJwt(token)
        let username = decodedToken.username
        let hashMd5 = md5(username + process.env.REACT_APP_MD5_HASH)
        let prestaUrl = localStorage.getItem('shopUrl')

        return (
            <Button
                variant="outlined"
                color="success"
                label={'Dodaj do koszyka'}
                onClick={() => {
                    let url =
                        prestaUrl +
                        '/superlogin.php?hash=' +
                        hashMd5 +
                        '&email=' +
                        username +
                        '&token=' +
                        token +
                        '&timestamp=' +
                        Date.now() +
                        '&redirect=true&product_sku=' +
                        record.sku +
                        '|' +
                        record.quantity
                    window.open(url, '_blank').focus()
                }}
            />
        )
    }

    const DatagridWrapper = (props) => {
        const record = useRecordContext()
        const { data, isLoading } = useListContext()

        return (
            <CustomDataGrid
                isAddToCartButton={true}
                permissions={permissions}
                isFooterOrderOne={true}
                footerOrderOneData={{
                    allNet: record?.net,
                    allGross: record?.gross,
                }}
            >
                <TextField source="bloz7" label="BLOZ7" emptyText="-" />
                <TextField source="ean" label="EAN" emptyText="-" />
                <TextField source="name" label="Nazwa" emptyText="-" />
                <NumberField
                    source="pricePerUnitNet"
                    label="Cena jednostkowa netto"
                    options={{
                        style: 'currency',
                        currency: 'PLN',
                        maximumFractionDigits: 2,
                    }}
                />
                <NumberField
                    source="pricePerUnitGross"
                    label="Cena jednostkowa brutto"
                    options={{
                        style: 'currency',
                        currency: 'PLN',
                        maximumFractionDigits: 2,
                    }}
                />
                <TextField source="quantity" label="ilość sztuk" />
                <FunctionField
                    label="Wartość netto"
                    render={(record) => `${(record.pricePerUnitNet * record.quantity).toFixed(2)} zł`.replace('.', ',')}
                />
                <FunctionField
                    label="Wartość brutto"
                    render={(record) => `${(record.pricePerUnitGross * record.quantity).toFixed(2)} zł`.replace('.', ',')}
                />

                {PermissionHandler(permissions, 'Dashboard', 'CanBuy') && (
                    <CustomAddToCartButton
                        {...props}
                        isAddToCart={true}
                        {...props}
                        label={
                            <MuiButton
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    let token = localStorage.getItem('token')
                                    let decodedToken = decodeJwt(token)
                                    let username = decodedToken.username
                                    let hashMd5 = md5(username + process.env.REACT_APP_MD5_HASH)
                                    let prestaUrl = localStorage.getItem('shopUrl')

                                    let skus = data.map((el) => el.sku + '|' + el.quantity).join(',')
                                    let url =
                                        prestaUrl +
                                        '/superlogin.php?hash=' +
                                        hashMd5 +
                                        '&email=' +
                                        username +
                                        '&token=' +
                                        token +
                                        '&timestamp=' +
                                        Date.now() +
                                        '&redirect=true&product_sku=' +
                                        skus

                                    window.open(url, '_blank').focus()
                                }}
                            >
                                Dodaj wszystkie pozycje
                                <br />
                                do koszyka
                            </MuiButton>
                        }
                    />
                )}
            </CustomDataGrid>
        )
    }

    const OrderProductsGrid = () => {
        const record = useRecordContext()
        return (
            <ReferenceManyField reference="product_orders" target="order">
                <DatagridWrapper />
                <CustomPagination />
            </ReferenceManyField>
        )
    }

    return (
        <Show {...props} title={<CustomTitle resourceName="Zamówienie" fields={['orderId']} />}>
            <SimpleShowLayout>
                <TextField source="nrZam" label="Numer zamówienia" />
                <DateField source="date" label="Data wystawienia" showTime={true} />
                <ReferenceField source="pickupPoint" reference="pickup_points" link={false} label="Punkt odbioru">
                    <TextField source="internalName" />
                </ReferenceField>
                {PermissionHandler(permissions, 'Orders', 'DpsView') && (
                    <ReferenceArrayField reference="departments" source="departments" label="Oddziały">
                        <SingleFieldList linkType={false} empty="Brak">
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                )}
                <TextField source="status" label="Status realizacji" />
                <BooleanField source="paymentStatus" label="Status płatności" valueLabelTrue="Opłacone" valueLabelFalse="Nieopłacone" />
                <TextField source="zrzam" label="Źródło" />
                <OrderProductsGrid label="Pozycje zamówienia" />
            </SimpleShowLayout>
        </Show>
    )
}
