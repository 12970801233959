import React, { useEffect, useState } from 'react'
import {
    AutocompleteInput,
    BooleanField,
    DateField,
    DateInput,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    useDataProvider,
    usePermissions,
    TextInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField
} from 'react-admin'
import CustomActionBar from '../CustomElements/CustomActionBar'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

import { Card, CardContent } from '@mui/material'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'
import { CustomPagination } from '../CustomElements/CustomPagination'

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const dataProvider = useDataProvider()
    const [data, setData] = useState()
    const [isFetched, setIsFetched] = useState(false)
    const [pendingOrders, setPendingOrder] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (PermissionHandler(permissions, 'Orders', 'GetDiscount')) {
            dataProvider.getOne('', { id: 'payers/get_discount' }).then(({ data }) => {
                setData(data)
            })
        }
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_pending_order`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => response.json())
            .then((json) => setPendingOrder(json?.[0]))
            .catch((e) => setError(e))
            .finally(() => setLoading(false))
    }, [isFetched || permissions])
    if (data != undefined && isFetched == false) setIsFetched(true)

    return (
        <>
            {pendingOrders && (
                <div className="w-100 newsletter-fix" align="Center">
                    <div className="col-xs-12">
                        <ul class="list-group list-group-flush">
                            <li className="list-group-item">
                                <label>
                                    Twoje zamówienie złożone o {new Date(pendingOrders?.dateCreated?.date).toLocaleTimeString('pl-Pl')} zostało
                                    przyjęte i przekazane do naszego systemu. Gdy zostanie nadany mu numer, zobaczysz je na liście poniżej.
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            <List
                {...props}
                pagination={<CustomPagination />}
                empty={<EmptyList title="zamówień" add={false} />}
                sort={{ field: 'date', order: 'DESC' }}
                actions={<CustomActionBar isAddVisible={false} />}
                bulkActionButtons={false}
                title={'Zamówienia'}
                filters={[
                    <DateInput label="Data po" source="date[after]" alwaysOn variant="outlined" resettable />,
                    <DateInput label="Data przed" source="date[before]" alwaysOn variant="outlined" resettable />,
                    <SelectInput
                        label="Źródło"
                        source="zrzam"
                        variant="outlined"
                        resettable
                        choices={[
                            { id: 'eCommerce', name: 'eCommerce' },
                            { id: 'inne źródło', name: 'Inne źródło' },
                        ]}
                        alwaysOn
                    />,
                    <SelectInput
                        label="Status płatności"
                        source="paymentStatus"
                        choices={[
                            { id: true, name: 'Opłacone' },
                            { id: false, name: 'Nieopłacone' },
                        ]}
                        alwaysOn
                        variant="outlined"
                        resettable
                    />,
                    <ReferenceInput source="pickupPoint" reference="pickup_points" link={false} className="autocompleteinput" alwaysOn allowEmpty>
                        <AutocompleteInput
                            label="Punkt odbioru"
                            optionText="internalName"
                            variant="outlined"
                            resettable
                            filterToQuery={(searchText) => ({
                                internalName: searchText,
                            })}
                        />
                    </ReferenceInput>,

                    <SelectInput
                        source="status"
                        variant="outlined"
                        resettable
                        choices={[
                            { id: 'Wydane', name: 'Wydane' },
                            { id: 'Zarejestrowane', name: 'Zarejestrowane' },
                            {
                                id: 'Oczekuje na zatwierdzenie',
                                name: 'Oczekuje na zatwierdzenie',
                            },
                            { id: 'Nieznany status', name: 'Nieznany status' },
                            {
                                id: 'Oczekuje na przesyłkę',
                                name: 'Oczekuje na przesyłkę',
                            },
                        ]}
                        alwaysOn
                    />,

                    PermissionHandler(permissions, 'Orders', 'CustomList') ? (
                        <TextInput source="orderNumber" variant="outlined" resettable label="Numer zamówienia (sklep)" alwaysOn />
                    ) : (
                        <></>
                    ),
                ]}
            >
                <CustomDataGrid isStatusColored={true} isFooterOrders={true}>
                    <TextField source="nrZam" label="Numer zamówienia" />
                    {PermissionHandler(permissions, 'Orders', 'CustomList') && <TextField source="orderNumber" label="Numer zamówienia (sklep)" />}
                    <DateField source="date" label="Data wystawienia" showTime={true} />
                    <ReferenceField
                        label="Punkt odbioru"
                        source="pickupPoint"
                        reference="pickup_points"
                        link={false}
                        //sortable={false}
                    >
                        <TextField source="internalName" />
                    </ReferenceField>
                    {PermissionHandler(permissions, 'Orders', 'DpsView') && (
                        <ReferenceArrayField reference="departments" source="departments" label="Oddziały">
                            <SingleFieldList linkType={false} empty="Brak">
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                    )}
                    <TextField source="status" label="Status realizacji" />
                    <BooleanField source="paymentStatus" label="Status płatności" valueLabelTrue="Opłacone" valueLabelFalse="Nieopłacone" />
                    <TextField source="net" label="Wartość netto w zł" />
                    <TextField source="gross" label="Wartość brutto w zł" />
                    <TextField source="zrzam" label="Źródło" />

                    <CustomActionsButtons
                        label="Szczegóły zamówenia"
                        isEditAvailable={PermissionHandler(permissions, 'Orders', 'Update')}
                        isDeleteAvailable={PermissionHandler(permissions, 'Orders', 'Delete')}
                        isDescriptionAvailable={true}
                    />

                    {PermissionHandler(permissions, 'Orders', 'RefreshInvoice') && (
                        <CustomActionsButtons label="Odśwież faktury" isRefreshInvoicesAvailable={true} />
                    )}
                </CustomDataGrid>
            </List>
        </>
    )
}
