import React from "react";
import { Datagrid, DatagridBody } from "react-admin";

const ProductsOrderFooter = (props) => {
  return (
    <tr style={{ textAlign: "center" }}>
      <td colSpan={5} style={{ padding: "20px 0 20px 0" }}></td>
      <th>Razem</th>
      <td>{props.footerOrderOneData.allNet} zł</td>
      <td>{props.footerOrderOneData.allGross} zł</td>
    </tr>
  );
};

const CustomDataGridBody = (props) => {
  return (
    <>
      <DatagridBody {...props} />
      {props.isFooterOrderOne && props.footerOrderOneData && (
        <ProductsOrderFooter {...props} />
      )}
    </>
  );
};

const CustomDataGrid = (props) => (
  <Datagrid
    {...props}
    body={<CustomDataGridBody {...props} />}
    sx={{
      "& .RaDatagrid-rowEven": {
        backgroundColor: "rgba(0,0,0,.07)",
      },
      "& .RaDatagrid-headerCell": {
        fontWeight: "bold",
        textAlign: "center",
      },
      "& .RaDatagrid-rowCell": {
        textAlign: "center",
      },
      ...props?.sx
    }}
    bulkActionButtons={null}
  />
);

export default CustomDataGrid;
