// 'ROLE_PAYER_VIRTUAL' - deprecated 'ROLE_PAYER_NETWORK' instead
const permissionsInfo = {
    Consents: {
        Create: [],
        Read: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        Checked: ['ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_DEPARTMENT', 'ROLE_DEPARTMENT_ADMIN'],
        Update: [],
        Delete: [],
    },
    Dashboard: {
        InfoRow: ['ROLE_MANAGER', 'ROLE_WORKER'],
        ContactForm: [
            'ROLE_PARTNER',
            'ROLE_PAYER_VIRTUAL',
            'ROLE_PAYER_NETWORK',
            'ROLE_PAYER',
            'ROLE_MANAGER',
            'ROLE_WORKER',
            'ROLE_DEPARTMENT',
            'ROLE_DEPARTMENT_ADMIN',
        ],
        ComplaintRow: [
            'ROLE_PARTNER',
            'ROLE_PAYER_VIRTUAL',
            'ROLE_PAYER_NETWORK',
            'ROLE_PAYER',
            'ROLE_MANAGER',
            'ROLE_WORKER',
            'ROLE_DEPARTMENT',
            'ROLE_DEPARTMENT_ADMIN',
        ],
        //CanBuy: [ 'ROLE_SUPER_ADMIN','ROLE_MANAGER', 'ROLE_WORKER'],
        CanBuy: ['ROLE_MANAGER', 'ROLE_WORKER'],
        BannerSwiper: [], //['ROLE_MANAGER', 'ROLE_WORKER'],
        ChartSwiper: [], //['ROLE_MANAGER', 'ROLE_WORKER'],
    },
    Banners: {
        Create: ['ROLE_SUPER_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN'],
        Delete: ['ROLE_SUPER_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN'],
    },
    MediaObjects: {
        Create: ['ROLE_SUPER_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN'],
        Delete: ['ROLE_SUPER_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN'],
    },
    Departments: {
        Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_DEPARTMENT_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_DEPARTMENT', 'ROLE_DEPARTMENT_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_DEPARTMENT_ADMIN'],
        Delete: [],
    },
    DepartmentUsers: {
        Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_DEPARTMENT', 'ROLE_DEPARTMENT_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_DEPARTMENT_ADMIN'],
        Delete: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
    DpsOrder: {
        Read: ['ROLE_DEPARTMENT', 'ROLE_DEPARTMENT_ADMIN'],
    },
    InfoPopup: {
        Create: [],
        Read: [
            'ROLE_PARTNER',
            'ROLE_MANAGER',
            'ROLE_PAYER_VIRTUAL',
            'ROLE_PAYER_NETWORK',
            'ROLE_PAYER',
            'ROLE_SUPER_ADMIN',
            'ROLE_WORKER',
            'ROLE_DEPARTMENT',
            'ROLE_DEPARTMENT_ADMIN',
        ],
        Update: [],
        Delete: [],
    },
    Invoices: {
        Create: [],
        Read: ['ROLE_MANAGER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PAYER', 'ROLE_WORKER'],
        Update: [],
        Delete: [],
        GetBalance: ['ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PAYER', 'ROLE_MANAGER'],
        BalanceView: ['ROLE_PAYER', 'ROLE_PAYER_VIRTUAL'],
        GetDelayedInvoiceNumber: ['ROLE_MANAGER'],
    },
    IndividualOrders: {
        Create: ['ROLE_WORKER', 'ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        Read: ['ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        Update: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    Institution: {
        Create: [],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_DEPARTMENT', 'ROLE_DEPARTMENT_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_DEPARTMENT_ADMIN'],
        Delete: [],
    },
    MyData: {
        PayerDataFV: ['ROLE_PAYER'],
        DiscountInfo: ['ROLE_MANAGER', 'ROLE_WORKER'],
    },
    Other: {
        CanLogin: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
    },
    Orders: {
        Create: [],
        Read: [
            'ROLE_SUPER_ADMIN',
            'ROLE_ADMIN',
            'ROLE_PAYER_VIRTUAL',
            'ROLE_PAYER_NETWORK',
            'ROLE_PAYER',
            'ROLE_MANAGER',
            'ROLE_WORKER',
            'ROLE_DEPARTMENT',
            'ROLE_DEPARTMENT_ADMIN',
        ],
        Update: [],
        Delete: [],
        GetDiscount: ['ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PAYER'],
        CustomList: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        RefreshInvoice: ['ROLE_SUPER_ADMIN'],
        DpsView: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_DEPARTMENT', 'ROLE_DEPARTMENT_ADMIN'],
    },
    Patients: {
        Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_DEPARTMENT', 'ROLE_DEPARTMENT_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_DEPARTMENT', 'ROLE_DEPARTMENT_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_DEPARTMENT_ADMIN'],
        Delete: [],
    },
    Payers: {
        Create: [],
        Read: [
            'ROLE_PARTNER',
            'ROLE_SUPER_ADMIN',
            'ROLE_ADMIN',
            'ROLE_PAYER_VIRTUAL',
            'ROLE_PAYER_NETWORK',
            'ROLE_TRAINER',
            'ROLE_DEPARTMENT',
            'ROLE_DEPARTMENT_ADMIN',
        ],
        ReadByNetwork: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN'],
        Delete: [],
        Actions: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_DEPARTMENT', 'ROLE_DEPARTMENT_ADMIN'],
        Refresh: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
    PayerNetworks: {
        Create: ['ROLE_SUPER_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        UpdatePriceList: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Delete: [],
    },
    Partners: {
        Create: [],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Update: [],
        Delete: [],
    },
    PartnerUsers: {
        Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Delete: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
    PartnerEmails: {
        Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Delete: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
    PayerNetworkUsers: {
        Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
        Delete: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
    },
    PkUsers: {
        Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Delete: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
    PayerUsers: {
        Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
        Delete: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
    },
    PayerDiscounts: {
        Create: [],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        Delete: [],
    },
    PickupPoints: {
        Create: [],
        Read: [
            'ROLE_SUPER_ADMIN',
            'ROLE_ADMIN',
            'ROLE_PAYER',
            'ROLE_PAYER_VIRTUAL',
            'ROLE_PAYER_NETWORK',
            'ROLE_WORKER',
            'ROLE_MANAGER',
            'ROLE_PARTNER',
            'ROLE_DEPARTMENT',
            'ROLE_DEPARTMENT_ADMIN',
        ],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        Delete: [],
        NumberStatusEdit: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        GenerateTemporaryUsers: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        ShowWorkers: [
            'ROLE_SUPER_ADMIN',
            'ROLE_ADMIN',
            'ROLE_PAYER',
            'ROLE_PAYER_VIRTUAL',
            'ROLE_PAYER_NETWORK',
            'ROLE_WORKER',
            'ROLE_MANAGER',
            'ROLE_PARTNER',
        ],
    },
    PriceLists: {
        Create: [],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Update: [],
        Delete: [],
    },
    Regulations: {
        Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Checked: [
            'ROLE_PAYER',
            'ROLE_PAYER_VIRTUAL',
            'ROLE_PAYER_NETWORK',
            'ROLE_MANAGER',
            'ROLE_WORKER',
            'ROLE_DEPARTMENT',
            'ROLE_DEPARTMENT_ADMIN',
        ],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Delete: [],
    },
    Samples: {
        Create: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        Delete: ['ROLE_SUPER_ADMIN'],
    },
    Tutorials: {
        Create: [],
        Read: [],
        Update: [],
        Delete: [],
        AdminViewRead: [],
        WorkerViewRead: [],
        PayerViewRead: [],
    },
    RefundMenu: {
        Create: [],
        Read: [
            'ROLE_SUPER_ADMIN',
            'ROLE_ADMIN',
            'ROLE_PAYER',
            'ROLE_PAYER_VIRTUAL',
            'ROLE_PAYER_NETWORK',
            'ROLE_WORKER',
            'ROLE_MANAGER',
            'ROLE_PARTNER',
        ],
        Update: [],
        Delete: [],
    },
    RefundCalc: {
        Create: [],
        Read: ['ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_SUPER_ADMIN'],
        Update: [],
        Delete: [],
    },
    RefundUpload: {
        Create: [],
        Read: [],
        Read: ['ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_SUPER_ADMIN', 'ROLE_PAYER_NETWORK'],
        Update: [],
        Delete: [],
    },
    RefundPrices: {
        Create: [],
        Read: [],
        Read: ['ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_SUPER_ADMIN'],
        Update: [],
        Delete: [],
    },
    RefundDocumentRealization: {
        Create: [],
        Read: [
            'ROLE_SUPER_ADMIN',
            'ROLE_ADMIN',
            'ROLE_DEPARTMENT',
            'ROLE_DEPARTMENT_ADMIN',
        ],
        Update: [],
        Delete: [],
    },
    RefundOther: {
        Create: [],
        Read: [
            'ROLE_SUPER_ADMIN',
            'ROLE_ADMIN',
            'ROLE_PAYER',
            'ROLE_PAYER_VIRTUAL',
            'ROLE_PAYER_NETWORK',
            'ROLE_WORKER',
            'ROLE_MANAGER',
            'ROLE_PARTNER',
        ],
        Update: [],
        Delete: [],
    },
    Trainings: {
        Create: [],
        Read: ['ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_SUPER_ADMIN'],
        Update: [],
        Delete: [],
    },

    Raports: {
        Create: [],
        Read: ['ROLE_SUPER_ADMIN'],
        Update: [],
        Delete: [],
    },
    /*
    AlertContacts: {
        Create: [],
        Read: ['ROLE_WORKER', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGER'],
        Update: [],
        Delete: [],
    },
    */
    Oracle: {
        Read: ['ROLE_SUPER_ADMIN'],
    },
    Workers: {
        Create: ['ROLE_MANAGER', 'ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
        Read: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK', 'ROLE_PARTNER'],
        Update: ['ROLE_SUPER_ADMIN', 'ROLE_MANAGER', 'ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
        Delete: ['ROLE_MANAGER', 'ROLE_PAYER', 'ROLE_PAYER_VIRTUAL', 'ROLE_PAYER_NETWORK'],
        IsWorkerActivated: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
    TechnicalPause: {
        Create: [],
        Read: ['ROLE_SUPER_ADMIN'],
        Update: [],
        Delete: [],
    },
    Default: {
        default: [],
    },
}

const PermissionHandler = (permissions = null, moduleName = 'Default', actionName = 'default') => {
    let result = false

    if (permissions && moduleName && actionName && permissionsInfo.hasOwnProperty(moduleName))
        permissions.split(',').map((x) => {
            if (permissionsInfo[moduleName][actionName]) if (permissionsInfo[moduleName][actionName].includes(x)) result = true
        })

    return result
}

export const isDpsUser = (permissions = null) => {
    return permissions && permissions?.includes('DEPARTMENT')
}

export default PermissionHandler
