import React, { useState } from 'react'
import {
    AutocompleteArrayInput,
    BooleanInput,
    Edit,
    email,
    FormDataConsumer,
    ReferenceArrayInput,
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    usePermissions,
    FunctionField,
} from 'react-admin'

import PermissionHandler from '../../config/PermissionHandler'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'
import { CustomTitle } from '../CustomElements/CustomTitle'

const requiredField = required('Pole wymagane')
const isEmail = [
    required('Pole wymagane'),
    email('Podany jest nie prawidłowy e-mail'),
]

const transform = (data, previousData) => ({
    ...data,
    type: parseInt(data.type),
    email: previousData.email,
})

export default (props) => {
    const { isLoading, permissions } = usePermissions()

    return (
        <Edit
            {...props}
            title={
                <CustomTitle
                    resourceName="pracownik"
                    fields={['name', 'surname']}
                />
            }
            transform={(data, { previousData }) =>
                transform(data, previousData)
            }
        >
            <SimpleForm
                toolbar={
                    <PostBottomToolbarEdit
                        isDeleteAvailable={PermissionHandler(
                            permissions,
                            'Workers',
                            'Delete'
                        )}
                    />
                }
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput
                            variant="outlined"
                            source="name"
                            validate={requiredField}
                            fullWidth
                            label="Imię"
                        />

                        <TextInput
                            variant="outlined"
                            source="surname"
                            validate={requiredField}
                            fullWidth
                            label="Nazwisko"
                        />

                        <SelectInput
                            source="type"
                            variant="outlined"
                            validate={requiredField}
                            choices={[
                                { id: 10, name: 'pracownik' },
                                { id: 20, name: 'kierownik' },
                            ]}
                            fullWidth
                            label="Typ"
                        />

                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                formData.type && (
                                    <tr>
                                        <td colSpan={2}>
                                            {formData.type == 10
                                                ? 'Składanie zamówień do przypisanego punktu odbioru, a także historia składanych zamówień dla danego punktu'
                                                : 'Ma możliwość przeglądania zamówień dla wszystkich przypisanych do siebie punktów odbioru a także składać zamówienie do wybranego punktu. Odpowiada także za dodawanie i zarządzanie pracownikami pierwszego stołu'}
                                        </td>
                                    </tr>
                                )
                            }
                        </FormDataConsumer>

                        <ReferenceArrayInput
                            source="pickupPoints"
                            reference="pickup_points"
                            validate={requiredField}
                            fullWidth
                        >
                            <AutocompleteArrayInput
                                variant="outlined"
                                optionText="internalName"
                                fullWidth
                                label="Punkty odbioru"
                                filterToQuery={(searchText) => ({
                                    internalName: searchText,
                                })}
                            />
                        </ReferenceArrayInput>

                        <TextInput
                            variant="outlined"
                            source="email"
                            validate={isEmail}
                            disabled
                            fullWidth
                            label="Adres e-mail"
                        />

                        <TextInput
                            variant="outlined"
                            source="phone"
                            fullWidth
                            label="Numer tel."
                        />

                        <BooleanInput
                            variant="outlined"
                            label="Status"
                            source="status"
                            fullWidth
                        />

                        <tr>
                            <td colSpan={2}>
                                Czy dany użytkownik będzie miał możliwość
                                logowania
                            </td>
                        </tr>
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}
